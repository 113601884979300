<template>
  <div class="story"
       v-on:click="$openPopupStories(params)">
    <div class="story__wrapper">
      <div class="story__image-holder">
        <img :src="image"
             class="story__image"
             alt=""/>
      </div>
      <Row v-if="show"
           class="story__badges -m-5"
           wrap>
        <Badge v-for="badge in params.tags"
               :key="`badge-story-${badge.id}`"
               :params="{text: badge.name, colors: {
                       text: '#ffffff', background: badge.color}}"/>
      </Row>
      <div class="story__title">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Story',
  props: {
    params: {},
  },
  computed: {
    show() {
      return this?.params?.tags?.length > 0;
    },
    image() {
      return this?.params?.imgs[0]?.image;
    },
    title() {
      return this?.params?.title;
    },
  },
};
</script>
